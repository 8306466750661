
import jquery from 'jquery';
export default (window.$ = window.jQuery = jquery);

import 'leaflet';
import 'magnific-popup';
import Filterizr from 'filterizr';

Filterizr.installAsJQueryPlugin($);


var mapBounds = new L.LatLngBounds(
    new L.LatLng(48.162638, 16.28667),
    new L.LatLng(48.250271, 16.444512));
  var mapMinZoom = 13;
  var mapMaxZoom = 17;
  var mapDonau = L.map('map-donau', { minZoom: mapMinZoom, maxZoom: mapMaxZoom, maxBounds: mapBounds }).setView([48.24247, 16.43296], 17);
  // var mapMaria = L.map('map-mh', { minZoom: mapMinZoom, maxZoom: mapMaxZoom, maxBounds: mapBounds}).setView([48.1963477, 16.3410297], 17);
  
  L.tileLayer('https://maps.rollincat.at/tiles/{z}/{x}/{y}.png', {
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(mapDonau);
  /* L.tileLayer('https://maps.rollincat.at/tiles/{z}/{x}/{y}.png', {
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(mapMaria);*/
  

var rollinIcon = L.icon({
    iconUrl: require('./images/rollincat_logo.png'),

    iconSize:     [50, 50], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [15, 50], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [10, -50] // point from which the popup should open relative to the iconAnchor
});

  L.marker([48.24247, 16.43296], {icon: rollinIcon}).bindPopup("Donauzentrum").addTo(mapDonau);
  //L.marker([48.1963477, 16.3410297], {icon: rollinIcon}).bindPopup("Mariahilferstraße 122").addTo(mapDonau);
  //L.marker([48.24247, 16.43296], {icon: rollinIcon}).bindPopup("Donauzentrum").addTo(mapMaria);
  //L.marker([48.1963477, 16.3410297], {icon: rollinIcon}).bindPopup("Mariahilferstraße 122").addTo(mapMaria);
  
  
  // TOGGLE DROPDOWN MENU
  // Show / Hide menu when clicked
  $('.has-sub').not('.toggle-hover').on('click', function(e) { // Find all with ID
    $('.dropdown-menu').not($(this).children('.dropdown-menu')).removeClass('dropdown-shown'); // Hide other menus
    $('.has-sub').not($(this)).removeClass('active');
    $(this).children('.dropdown-menu').toggleClass('dropdown-shown');
    $(this).toggleClass('active');
  });
  
  $('section').not('#header-btn').on('click', function(e) { // Hide when clicking section (will modify later)
    $('.dropdown-menu').removeClass('dropdown-shown'); // Hide other menus clicking on window
    $('#header-menu').removeClass('active');
    $('.nav-item').removeClass('active');
  });
  
  
  // TOGGLE HEADER-NAV
  // Show dropdown when clicked
  $('#header-btn').on('click', function(e) {
    $('#header-menu').toggleClass('active');
    $('.nav-btn').toggleClass('active');
  });
  
  // Hide menu after clicking menu item
  $('.dropdown-menu li').on('click', function(e) {
    $('#header-menu').removeClass('active');
    $('.nav-btn').removeClass('active');
  });
  
  //GALLERY
  $(document).ready(function() {
    $('.gallery-popup').magnificPopup({
      type: 'image',
      gallery:{
        enabled:true
      }
    });

    // Use Filterizr as a jQuery plugin
    $('.filter-cnt').filterizr({
      filter: 'dz',
      layout: 'horizontal',
      spinner: {
        enabled: true,
      }
    });

    $('.tab-item-content').on( "click", function() {
      $('.tab-container li').removeClass("selected");
      $(this).parent('li').addClass("selected");
    });
    
  });

